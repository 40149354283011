import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@core/services/data.service';
import { Site } from '../site.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// set default form field appearance as fill for this component
const appearance: MatFormFieldDefaultOptions = {
	appearance: 'outline'
};


@Component({
	selector: 'app-site-add-edit',
	templateUrl: './site-add-edit.component.html',
	styleUrls: ['./site-add-edit.component.scss'],
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: appearance
		}
	]
})
export class SiteAddEditComponent implements OnInit, OnDestroy {

	siteId: number;
	site: Site;
	isAddMode: boolean;

	form: UntypedFormGroup

	changesDetected: boolean = false;
	snackOptions: any = { duration: 5000, panelClass: 'success-snack' };

	private unsubscribe$ = new Subject<void>();

	constructor(
		private dataService: DataService,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar
	) { }

	ngOnInit(): void {

		this.siteId = this.route.snapshot.params['id'];
		this.isAddMode = !this.siteId;

		this.form = new UntypedFormGroup({});

		this.form = this.formBuilder.group({
			visibility: new UntypedFormControl('', [Validators.required]),
			visibilityDescription: ['', [Validators.maxLength(1000)]],
			station: this.formBuilder.group({
				id: [null],
				name: ['', [Validators.required]],
			}),
			space: this.formBuilder.group({
				id: [null],
				name: ['', [Validators.required]],
			}),
			name: new UntypedFormControl('', [Validators.required]),
			capacityTotal: new UntypedFormControl(null),
			protocol: new UntypedFormControl('', [Validators.required]),
			destinationId: new UntypedFormControl(''),
			isRecord: new UntypedFormControl(''),
			isAggregate: new UntypedFormControl('')
		})

		if (!this.isAddMode) {
			this.dataService.getSite(this.siteId).subscribe((data: any) => {
				this.site = data;
				this.form.patchValue(data)
				// Detect form changes after values patched
				this.form.valueChanges.subscribe(() => { this.changesDetected = true })
			})
		}


		// Listen for changes to the protocol form control
		this.form.get('protocol').valueChanges
		.pipe(takeUntil(this.unsubscribe$))
		.subscribe((protocol) => {
			// If protocol is not 'ARIVO', set capacityTotal to null
			if (protocol !== 'ARIVO') {
				this.form.get('capacityTotal').setValue(null);
			}
		});

	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	onSubmit() {
		if (this.form.invalid) return;

		if (this.isAddMode) this.addSite();
		else this.editSite();
	}

	private addSite() {
		this.dataService.postSite(this.form.value).subscribe((data: any) => {
			this.snackBar.open('Parkbereich erfolgreich erstellt.', null, this.snackOptions);
			this.router.navigate(['/site', data.id]);
		})
	}

	private editSite() {
		this.dataService.putSite(this.siteId, this.form.value).subscribe((data: any) => {
			this.site = data;
			this.snackBar.open('Parkbereich erfolgreich aktualisiert.', null, this.snackOptions);
		})
	}



}
