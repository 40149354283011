<div class="flex flex-col pb-5">
    
    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
    
        <div>
            <h1 class="mat-display-1" *ngIf="isAddMode">Neues Parkbereich erstellen</h1>
            <h1 class="mat-display-1" *ngIf="!isAddMode">Parkbereich bearbeiten: <b>{{site?.title}}</b></h1>
            <span class="mat-caption" *ngIf="!isAddMode">
                <b>ID:</b> {{siteId}} |
                <b>Erzeugt:</b> {{site?.created | date: 'dd.MM.yyyy - HH:mm:ss'}} Uhr von {{site?.creator}} |
                <b>Zuletzt geändert:</b> {{site?.edited | date: 'dd.MM.yyyy - HH:mm:ss'}} Uhr von {{site?.editor}}
            </span>
        </div>

        <div>
            <button type="submit" mat-raised-button color="primary" form="ngForm">
                SPEICHERN
            </button>
            <button [routerLink]="['/site']" type="button" mat-raised-button [style.margin-left.px]="16">
                ZURÜCK
            </button>
        </div>

    </div>

    <form [formGroup]="form" id="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">

        <div class="masonry-mat-cards masonry-mat-cards-grid-3 px-5 pt-1">

            <mat-card>

                <mat-card-title>Sichtbarkeit</mat-card-title>

                <mat-card-content class="!flex flex-col">

                    <mat-form-field>
                        <mat-label>Status</mat-label>
                        <mat-select matInput formControlName="visibility" required>
                            <mat-option [value]="'ONLINE'">ONLINE</mat-option>
                            <mat-option [value]="'OFFLINE'">OFFLINE</mat-option>
                            <mat-option [value]="'PENDING'">PRÜFEN</mat-option>
                            <mat-option [value]="'HIDDEN'">VERSTECKT</mat-option>
                            <mat-option [value]="'DELETED'">GELÖSCHT</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Sichtbarkeit (Grund)</mat-label>
                        <textarea matInput formControlName="visibilityDescription"
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="10">
                        </textarea>
                        <mat-error *ngIf="form.get('visibilityDescription').hasError('maxlength')">
                            Die maximale Länge beträgt 1000 Zeichen.
                        </mat-error>
                    </mat-form-field>

                </mat-card-content>
            
            </mat-card>

            <mat-card>

                <mat-card-title>Parkbereich</mat-card-title>

                <mat-card-content class="!flex flex-col">

                    <site-autocomplete-stations></site-autocomplete-stations>

                    <site-autocomplete-spaces></site-autocomplete-spaces>

                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" required>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Protokoll</mat-label>
                        <mat-select matInput formControlName="protocol" required>
                            <mat-option [value]="'ARIVO'">ARIVO</mat-option>
                            <mat-option [value]="'AUTOPAY'">AUTOPAY</mat-option>
                            <mat-option [value]="'DAMBACH'">DAMBACH</mat-option>
                            <mat-option [value]="'DESIGNA'">DESIGNA</mat-option>
                            <mat-option [value]="'MSR'">MSR</mat-option>
                            <mat-option [value]="'PARKHERE'">PARKHERE</mat-option>
                            <mat-option [value]="'PARKSPOTTING'">PARKSPOTTING</mat-option>
                            <mat-option [value]="'PITSPOT'">PITSPOT</mat-option>
                            <mat-option [value]="'SKIDATA'">SKIDATA</mat-option>
                            <mat-option [value]="'SMARTCITY'">SMARTCITY</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="form.get('protocol').value === 'ARIVO'">
                        <mat-label>Kapazität</mat-label>
                        <input matInput formControlName="capacityTotal">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Destination ID</mat-label>
                        <input matInput formControlName="destinationId">
                    </mat-form-field>

                    <mat-checkbox formControlName="isRecord">
                        Belegungsdaten aufzeichnen
                    </mat-checkbox>

                    <mat-checkbox formControlName="isAggregate">
                        Belegungsdaten in Parkraum aggregieren
                    </mat-checkbox>
                
                </mat-card-content>

            </mat-card>
  
        </div>

    </form>


</div>

<!--
<div style="
    display: none; 
    position: fixed; 
    right: 0; 
    top:154px; 
    bottom:0; 
    width:25vw; 
    height: calc(100% - 154px);
    background:white; 
    overflow: scroll;
    padding:1em;
    box-shadow:1px 1px 1px #444">
    <pre><code>{{ form.value | json }}</code></pre>
    invalid: {{form.invalid}}
</div>
-->