<div class="flex flex-col pb-5">
    
    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">

        <div class="flex items-center justify-start">
            <div *ngIf="!isAddMode">
                <div *ngIf="mobi?.publishingStatus === 'PUBLISHED'" class="publishing-status published">Publiziert</div>
                <div *ngIf="mobi?.publishingStatus === 'ARCHIVED'" class="publishing-status archived">Archiviert</div> 
                <div *ngIf="mobi?.publishingStatus === 'DRAFT'" class="publishing-status draft">Entwurf</div>  
            </div>

            <div>
                <h1 class="mat-display-1" *ngIf="isAddMode">Neues Mobilitätsangebot erstellen</h1>
                <h1 class="mat-display-1" *ngIf="!isAddMode">Mobilitätsangebot bearbeiten: <b>{{mobi?.title}}</b> </h1>
                <span class="mat-caption" *ngIf="!isAddMode">
                    <b>ID:</b> {{mobiId}} |
                    <b>Erzeugt:</b> {{mobi?.created | date: 'dd.MM.yyyy - HH:mm:ss'}} Uhr von {{mobi?.creator}} |
                    <b>Zuletzt geändert:</b> {{mobi?.edited | date: 'dd.MM.yyyy - HH:mm:ss'}} Uhr von {{mobi?.editor}}
                </span>
            </div>
        </div>

        <div>
            <button type="submit" mat-raised-button color="primary" form="ngForm">
                SPEICHERN
            </button>
            <button type="button" [routerLink]="['/mobi']" mat-raised-button [style.margin-left.px]="16">
                ZURÜCK
            </button>
        </div>

    </div>

    <div *ngIf="!isAddMode" class="publishing-card sticky top-[164px]">
        <mat-card class="!flex justify-between items-center mat-elevation-z0">
            <div>
                <div *ngIf="mobi?.publishingStatus === 'PUBLISHED'" class="published">
                    Dieses Mobilitätsangebot wurde am {{mobi?.published | date: 'dd.MM.YYYY \'um\' HH:ss:mm \'Uhr\''}} publiziert.
                </div>
                <div *ngIf="mobi?.publishingStatus === 'ARCHIVED'" class="archived">
                    Dieses Mobilitätsangebot ist archiviert. Publiziert am {{mobi?.published | date: 'dd.MM.YYYY \'um\' HH:ss:mm \'Uhr\''}}.
                </div> 
                <div *ngIf="mobi?.publishingStatus === 'DRAFT'" class="draft">
                    <button class="mr-5" mat-raised-button color="primary" (click)="publishMobi()">Publizieren</button>Dieses Mobilitätsangebot ist ein Entwurf.
                </div>  
            </div>  
            <div class="flex items-center">
                <div class="pr-5">Version {{mobi?.version}}</div>
                <button mat-raised-button (click)="navigateToVersion(mobi?.version - 1)" [disabled]="!hasPrev" aria-label="Previous version" [style.margin-right.px]="16">
                    <mat-icon>arrow_left</mat-icon> Frühere Version
                </button>
                <button mat-raised-button (click)="navigateToVersion(mobi?.version + 1)" [disabled]="!hasNext" aria-label="Next version">
                    Spätere Version <mat-icon>arrow_right</mat-icon>
                </button>
            </div> 
        </mat-card>
    </div>

    <form [formGroup]="form" id="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">

        <div class="masonry-mat-cards masonry-mat-cards-grid-3 px-5 pt-1">

            <div>            
                <mat-card>

                    <mat-card-title>Sichtbarkeit</mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <mat-form-field>
                            <mat-label>Status</mat-label>
                            <mat-select matInput formControlName="visibility">
                                <mat-option [value]="'ONLINE'">ONLINE</mat-option>
                                <mat-option [value]="'OFFLINE'">OFFLINE</mat-option>
                                <mat-option [value]="'PENDING'">PRÜFEN</mat-option>
                                <mat-option [value]="'HIDDEN'">VERSTECKT</mat-option>
                                <mat-option [value]="'DELETED'">GELÖSCHT</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Sichtbarkeit (Grund)</mat-label>
                            <textarea matInput formControlName="visibilityDescription"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                            <mat-error *ngIf="form.get('visibilityDescription').hasError('maxlength')">
                                Die maximale Länge beträgt 1000 Zeichen.
                            </mat-error>
                        </mat-form-field>

                    </mat-card-content>
                
                </mat-card>

                <mat-card>

                    <mat-card-title>Ausspielkanäle</mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <mat-selection-list #channelList formControlName="channels">
                            <mat-list-option *ngFor="let channel of channels; let last = last" [value]="channel.token">
                                {{channel.token}}<br>
                                <mat-hint style="font-size:75%">{{channel.description}}</mat-hint>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </mat-list-option>
                        </mat-selection-list>  
                        
                    </mat-card-content>
                
                </mat-card>
            </div>

            <mat-card>

                <mat-card-title>Informationen zum Mobilitätsangebot</mat-card-title>

                <mat-card-content class="!flex flex-col">

                    <mat-form-field appearance="outline">
                        <mat-label>Mobilitäts-Typ</mat-label>
                        <mat-select formControlName="mobiType" disableOptionCentering required>
                            <mat-option>Alle</mat-option>
                            <mat-option *ngFor="let option of mobiTypeOptions" [value]="option.value">{{option.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Anzahl</mat-label>
                        <input matInput formControlName="numberPlaces">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Mobilitätsnummer</mat-label>
                        <input matInput formControlName="mobiNumber">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Bermerkung</mat-label>
                        <input matInput formControlName="notes">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Bemerkung intern</mat-label>
                        <input matInput formControlName="notesInternal">
                    </mat-form-field>

                    <mobi-autocomplete-stations></mobi-autocomplete-stations>

                </mat-card-content>

            </mat-card>

            <mat-card>

                <mat-card-title>Koordinaten</mat-card-title>

                <mat-card-content formArrayName="geoLocation" class="!flex flex-col">

                    <mat-form-field>
                        <mat-label>Längengrad</mat-label>
                        <input matInput formControlName="longitude">
                    </mat-form-field>   

                    <mat-form-field>
                        <mat-label>Breitengrad</mat-label>
                        <input matInput formControlName="latitude">
                    </mat-form-field>

                </mat-card-content>
            
            </mat-card>

        </div>

    </form>


</div>

<!--
<div style="
    display: none; 
    position: fixed; 
    right: 0; 
    top:218px; 
    bottom:0; 
    width:25vw; 
    height: calc(100% - 154px);
    background:white; 
    overflow: scroll;
    padding:1em;
    box-shadow:1px 1px 1px #444">
    <pre><code>{{ form.value | json }}</code></pre>
</div>

<div style="
    display: none; 
    position: fixed; 
    right: 25vw; 
    top:154px; 
    bottom:0; 
    width:25vw; 
    height: calc(100% - 154px);
    background:white; 
    overflow: scroll;
    padding:1em;
    box-shadow:1px 1px 1px #444">
    <pre><code>{{ patchedFormValue | json }}</code></pre>
</div>
-->