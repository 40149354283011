<div class="flex flex-col pb-5">
    
    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">
        
        <div>
            <h1 class="mat-display-1" *ngIf="isAddMode">Neuen Bahnhof erstellen</h1>
            <h1 class="mat-display-1" *ngIf="!isAddMode">Bahnhof bearbeiten: <b>{{station?.name}}</b></h1>
            <span class="mat-caption" *ngIf="!isAddMode">
                <b>ID:</b> {{stationId}} |
                <b>Erzeugt:</b> {{station?.created | date: 'dd.MM.yyyy - HH:mm:ss'}} Uhr von {{station?.creator}} |
                <b>Zuletzt geändert:</b> {{station?.edited | date: 'dd.MM.yyyy - HH:mm:ss'}} Uhr von {{station?.editor}}
            </span>
        </div>

        <div>
            <button type="submit" mat-raised-button color="primary" form="ngForm">
                SPEICHERN
            </button>
            <button [routerLink]="['/station']" type="button" mat-raised-button class="!ml-[16px]">
                ZURÜCK
            </button>
        </div>

    </div>

    <form [formGroup]="form" id="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">

        <div class="masonry-mat-cards masonry-mat-cards-grid-3 px-5 pt-1">

            <div>

                <mat-card>

                    <mat-card-title>Sichtbarkeit</mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <mat-form-field>
                            <mat-label>Status</mat-label>
                            <mat-select matInput formControlName="visibility">
                                <mat-option [value]="'ONLINE'">ONLINE</mat-option>
                                <mat-option [value]="'OFFLINE'">OFFLINE</mat-option>
                                <mat-option [value]="'PENDING'">AUSSTEHEND</mat-option>
                                <mat-option [value]="'HIDDEN'">VERSTECKT</mat-option>
                                <mat-option [value]="'DELETED'">GELÖSCHT</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Sichtbarkeit (Grund)</mat-label>
                            <input matInput formControlName="visibilityDescription">
                        </mat-form-field>

                    </mat-card-content>
                
                </mat-card>

                <mat-card>

                    <mat-card-title>Ausspielkanäle</mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <mat-selection-list #channelList formControlName="channels">
                            <mat-list-option *ngFor="let channel of channels; let last = last" [value]="channel.token">
                                {{channel.token}}<br>
                                <mat-hint style="font-size:75%">{{channel.description}}</mat-hint>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </mat-list-option>
                        </mat-selection-list>  
                        
                    </mat-card-content>
                
                </mat-card>

            </div>
            <div>

                <mat-card>

                    <mat-card-title>Bahnhof</mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <mat-form-field>
                            <mat-label>Bahnhofsnummer</mat-label>
                            <input type="number" matInput formControlName="stationNumber" required>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" required>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Kategorie Verkehrsstation</mat-label>
                            <input matInput formControlName="categoryVS">
                        </mat-form-field>

                        <mat-form-field class="mb-2">
                            <mat-label>EVA Nummer(n)</mat-label>
                            <mat-chip-list #chipList aria-label="EVA Numbers" multiple formControlName="evaNumbers">
                            <mat-chip *ngFor="let evaNumber of form.get('evaNumbers').value"
                                    [value]="evaNumber"
                                    (removed)="removeEvaNumber(evaNumber)">
                                {{evaNumber}}
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input
                                placeholder="EVA Nummer hinzufügen..."
                                [matChipInputFor]="chipList"
                                (matChipInputTokenEnd)="addEvaNumberFromInput($event)">
                            </mat-chip-list>
                            <mat-hint>Eine oder mehrere Ids eintragen und mit ENTER bestätigen.</mat-hint>
                        </mat-form-field>

                        {{form.get('evaNumbers').value | json}}

                        <mat-form-field>
                            <mat-label>SBN</mat-label>
                            <input matInput formControlName="sbn">
                        </mat-form-field>

                        <station-autocomplete-cities></station-autocomplete-cities>

                        <mat-form-field>
                            <mat-label>Bundesland</mat-label>
                            <input matInput formControlName="federalState">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Regionalberech St&S</mat-label>
                            <input matInput formControlName="regionalDistrict">
                        </mat-form-field>

                    </mat-card-content>

                </mat-card>

            </div>
            <div>

                <mat-card>
                    
                    <mat-card-title>Adresse</mat-card-title>
                    
                    <mat-card-content class="!flex flex-col">
                        
                        <mat-form-field>
                            <mat-label>Straße & Hausnummer</mat-label>
                            <input matInput formControlName="addressStreet">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Postleitzahl</mat-label>
                            <input matInput formControlName="addressPostalCode">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Stadt</mat-label>
                            <input matInput formControlName="addressCityName">
                        </mat-form-field>

                    </mat-card-content>

                </mat-card>

                <mat-card>
                    
                    <mat-card-title>Koordinaten</mat-card-title>
                    
                    <mat-card-content class="!flex flex-col" formGroupName="geoLocation">
                        
                        <mat-form-field>
                            <mat-label>Längengrad</mat-label>
                            <input matInput formControlName="longitude">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Breitengrad</mat-label>
                            <input matInput formControlName="latitude">
                        </mat-form-field>

                    </mat-card-content>

                </mat-card>

                <mat-card>
                    
                    <mat-card-title>Parkopedia</mat-card-title>
                    
                    <mat-card-content class="!flex flex-col">
                        
                        <mat-form-field class="mb-4">
                            <mat-label>Import Radius</mat-label>
                            <input matInput formControlName="parkopediaImportRadius">
                            <mat-hint>Parkopedia Import Radius in Metern. (Ohne Eintrag = Kat. VS 1: 500 m, Kat. VS 2: 350 m, höhere Kat. VS: 250 m)</mat-hint>
                        </mat-form-field>

                        <mat-checkbox formControlName="isParkopediaAutoImport" [style.margin-top.px]="16">Parkopedia Import Auto-Publish</mat-checkbox>   

                    </mat-card-content>

                </mat-card>

            </div>

        </div>

    </form>

</div>


<pre class="hidden fixed z-50 bottom-0 right-0 bg-antiquewhite h-1/2">
    <code>{{this.form.value | json}}</code>
</pre>