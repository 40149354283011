import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { SiteParameterReset, SiteParameterSelected } from 'app/site/states/site-filter.action';
import { SiteParameterState } from 'app/site/states/site-filter.state';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-site-filter',
  templateUrl: './site-filter.component.html',
  styleUrls: ['./site-filter.component.scss']
})
export class SiteFilterComponent implements OnInit {

	@Output() filterChanged = new EventEmitter<any>();

	form: UntypedFormGroup

	onKeyup = new Subject<KeyboardEvent>();

	visibilityOptions = [
		{'name': 'Online', 'value': 'ONLINE'},
		{'name': 'Offline', 'value': 'OFFLINE'},
		{'name': 'Versteckt', 'value': 'HIDDEN'}
	];

	protocolOptions = [
		{ name: 'ARIVO', value: 'ARIVO' },
		{ name: 'AUTOPAY', value: 'AUTOPAY' },
		{ name: 'DAMBACH', value: 'DAMBACH' },
		{ name: 'DESIGNA', value: 'DESIGNA' },
		{ name: 'MSR', value: 'MSR' },
		{ name: 'PARKHERE', value: 'PARKHERE' },
		{ name: 'PARKSPOTTING', value: 'PARKSPOTTING' },
		{ name: 'PITSPOT', value: 'PITSPOT' },
		{ name: 'SKIDATA', value: 'SKIDATA' },
		{ name: 'SMARTCITY', value: 'SMARTCITY' }
	];

	constructor(
		private formBuilder: UntypedFormBuilder,
		private store: Store
	) { }

	ngOnInit(): void {

		this.form = new UntypedFormGroup({});

        this.form = this.formBuilder.group({
			name: new UntypedFormControl(''),
            visibility: new UntypedFormControl(''),
			protocol: new UntypedFormControl(''),
        })

		this.form.patchValue(
			this.store.selectSnapshot(SiteParameterState.getCurrentParams)
		)

		this.onKeyup.pipe(
			map((event: any) => event.target.value),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe(() => { 
			this.onSelect() 
		});
		
	}

	onSelect() {
		this.filterChanged.emit()
		this.store.dispatch(new SiteParameterSelected(this.form.value))
	}

	resetFilter() {
		this.form.reset();
		this.store.dispatch(new SiteParameterReset())
	}
}
