import { CurrencyPipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@core/services/data.service';
import { Channel } from 'app/channel/channel.interface';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Mobi } from '../mobi.interface';

// Set default form field appearance as fill for this component
const appearance: MatFormFieldDefaultOptions = {
	appearance: 'outline'
};


@Component({
	selector: 'app-mobi-add-edit',
	templateUrl: './mobi-add-edit.component.html',
	styleUrls: ['./mobi-add-edit.component.scss'],
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: appearance
		}
	]
})
export class MobiAddEditComponent implements OnInit, AfterViewChecked {

	// Current mobi id from param
	mobiId: number;
	// Current mobi object
	mobi: Mobi;
	// Snapshot of current patched form value
	patchedFormValue: Mobi;

	isAddMode: boolean;

	hasPrev: boolean = false;
	hasNext: boolean = false;

	channels: Channel[];

	snackOptions: MatSnackBarConfig = { duration: 5000, panelClass: 'success-snack' };

	form: UntypedFormGroup;

	mobiTypeOptions = [
		{ 'name': 'Fahrradbox', 'value': 'BIKE_BOX' },
		{ 'name': 'Stellplatz für Lastenräder', 'value': 'BIKE_CARGO' },
		{ 'name': 'Fahrradparkhaus oder -sammelanlage', 'value': 'BIKE_GARAGE' },
		{ 'name': 'Fahrradabstellanlage', 'value': 'BIKE_PARKING' },
		{ 'name': 'Leihfahrräder', 'value': 'BIKE_RENTAL' },
		{ 'name': 'Fahrradservicestation', 'value': 'BIKE_SERVICE' },
		{ 'name': 'Autovermietung', 'value': 'CAR_RENTAL' },
		{ 'name': 'Reservierung/Vorverkauf PKW-Stellplatz', 'value': 'CAR_PARKING_SERVICE' },
		{ 'name': 'Carsharing', 'value': 'CAR_SHARING' },
		{ 'name': 'E-Laden PKW', 'value': 'E_CAR_CHARGING' },
		{ 'name': 'E-Laden Fahrrad', 'value': 'E_BIKE_CHARGING' },
		{ 'name': 'E-Roller', 'value': 'E_ROLLER' },
		{ 'name': 'E-Tretroller', 'value': 'E_SCOOTER' },
		{ 'name': 'Kiss&Ride', 'value': 'KISS_AND_RIDE' },
		{ 'name': 'Ridehailing/Ruftaxi', 'value': 'RIDE_HAILING' },
		{ 'name': 'Ridepooling/Ruf-Sammeltaxi', 'value': 'RIDE_POOLING' },
		{ 'name': 'Paketstation', 'value': 'PARCEL_STATION' }
	];

	@ViewChild(MatAccordion) accordion: MatAccordion;

	constructor(
		private dataService: DataService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar,
		private readonly changeDetectorRef: ChangeDetectorRef
	) { }

	// A better solution would be calling detectChanges() just when its needed (on validation)
	// but for now its working fine...
	ngAfterViewChecked(): void {
		this.changeDetectorRef.detectChanges();
	}

	ngOnInit(): void {
		// Get id parameter from route
		this.mobiId = this.route.snapshot.params['id'];
		// Add or edit mode
		this.isAddMode = !this.mobiId;
		// Get channels
		this.getChannels();
		// Build the formular
		this.buildForm();
		// If edit mode patch mobi values
		if (!this.isAddMode) {
			// Get current version param
			const version = this.route.snapshot.params['version'];
			// Get mobi from data service
			this.loadMobiData(version).then((data: any) => {
				// Current version data
				this.mobi = data[1];
				// Patch current version data
				this.patchMobiValues();
				// Check if previous and next version are available
				this.hasNext = data[2] ? true : false;
				this.hasPrev = data[0] ? true : false;
			})
		}

	}

	/** HTTP request of mobi by ID */
	loadMobiData(currentVersion: any): Promise<Mobi[]> {
		// Convert into number
		const version: number = parseInt(currentVersion)
		// Get mobi data from all 3 versions, skip http error interceptor
		const versions: number[] = [
			version - 1,
			version,
			version + 1
		];

		return forkJoin([
			this.dataService.getMobi(this.mobiId, versions[0], false).pipe(catchError(() => of(null))),
			this.dataService.getMobi(this.mobiId, versions[1], false).pipe(catchError(() => of(null))),
			this.dataService.getMobi(this.mobiId, versions[2], false).pipe(catchError(() => of(null)))
		]).toPromise();
	}

	/** Get channel list via http request */
	getChannels(): void {
		this.dataService.getChannels().subscribe((data: Channel[]) => {
			this.channels = data.map((item: Channel) => ({
				token: item.token,
				description: item.description,
				checked: false
			}));
		});
	}

	/** Patch mobi data into form */
	patchMobiValues(): void {
		// Convert into displayed values before patching
		this.mobi = this.convertDisplayedValues(this.mobi);
		// Reset entire form first
		this.form.reset();
		// Patch with formatted mobi data
		this.form.patchValue(this.mobi, { emitEvent: false });
		// Get patched mobi data (raw because parkopedia form is mostly disabled)
		this.patchedFormValue = this.form.getRawValue(); // value;
	}

	/** Mobi version navigator */
	navigateToVersion(version: number): void {
		console.log('navigate to: ', version)
		// Navigate to desired version then patch new data to formular
		this.router.navigate(['/mobi/', this.mobiId, version]).then(() => {
			this.loadMobiData(version).then((data: any) => {
				// Check if a version before and after exists
				this.hasPrev = data[0] ? true : false;
				this.hasNext = data[2] ? true : false;
				// Set mobi data from http request
				this.mobi = data[1];
				// Patch current version
				this.patchMobiValues();
			})
		})
	}

	/** Check before submit */
	public onSubmit() {
		// If form is invalid
		if (this.form.invalid) {
			this.snackBar.open(
				'Validierung fehlgeschlagen. Bitte überprüfen Sie die rot makierten Eingabefelder!',
				'Schließen',
				{ duration: 10000, panelClass: 'error-snack' }
			)
			return;
		}
		// Reconvert displayed values back into database format
		this.mobi = this.reconvertDisplayedValue(this.form.getRawValue());
		// Add or edit mobi
		if (!this.isAddMode) this.editMobi();
		else this.addMobi();
	}

	/** POST new mobi */
	private addMobi() {
		this.dataService.postMobi(this.mobi).subscribe((data: any) => {
			this.snackBar.open('Das Mobilitätsangebot wurde erfolgreich angelegt.', null, this.snackOptions);
			this.router.navigate(['/mobi', data.id, data.version]);
		})
	}

	/** PUT in existing mobi */
	private editMobi() {
		// Is current mobi status "PUBLISHED"
		const isPublished = this.mobi.publishingStatus === 'PUBLISHED' ? true : false;
		// Save edited mobi
		console.log(this.mobi)
		this.dataService.putMobi(this.mobiId, this.mobi).subscribe((data: any) => {
			// Update mobi from http request
			this.mobi = data;
			// Patch new data into mobi form
			this.patchMobiValues();

			this.navigateToVersion(this.mobi.version)

			this.snackBar.open(
				isPublished ? `Ein neuer Entwurf (Version ${this.mobi.version}) wurde erstellt.`
					: `Entwurf wurde erfolgreich aktualisiert.`,
				null,
				this.snackOptions
			);

		})
	}

	/** PUBLISH draft mobi */
	public publishMobi() {
		// Changes detected or else publish mobi
		if (this.detectChanges(this.patchedFormValue, this.form.getRawValue())) {
			this.snackBar.open(
				'Es wurden Änderungen an diesem Entwurf vorgenommen. Bitte speichern Sie diese vorher.',
				'Okay!',
				{ duration: 5000, panelClass: 'alert-snack' }
			)
		} else {
			this.dataService.publishMobi(this.mobiId, this.mobi.version).subscribe((data: any) => {
				this.mobi = data;
				this.snackBar.open('Parkraum erfolgreich publiziert.', null, this.snackOptions);
			})
		}
	}

	/** Detect if there are changes between two objects */
	detectChanges(obj1: any, obj2: any): boolean {
		return JSON.stringify(obj1) !== JSON.stringify(obj2)
	}
	/** Convert into displayed form vaules */
	convertDisplayedValues(mobi: Mobi): Mobi {
		// Format geo locations. Replace dots with commas.
		if (mobi.geoLocation.latitude) mobi.geoLocation.latitude = mobi.geoLocation.latitude.toString().replace('.', ',');
		if (mobi.geoLocation.latitude) mobi.geoLocation.longitude = mobi.geoLocation.longitude.toString().replace('.', ',');

		return mobi;
	}

	/** Reconverts displayed values back into database format */
	reconvertDisplayedValue(mobi: Mobi): Mobi {
		// Format geo locations. Relpace commas with dots.
		if (mobi.geoLocation) {
			if (mobi.geoLocation.latitude)
				typeof mobi.geoLocation.latitude === 'string' ?
					mobi.geoLocation.latitude = mobi.geoLocation.latitude.replace(',', '.') : ''
			if (mobi.geoLocation.longitude) {
				typeof mobi.geoLocation.longitude === 'string' ?
					mobi.geoLocation.longitude = mobi.geoLocation.longitude.replace(',', '.') : ''
			}
		}
		return mobi;
	}

	/** Build form */
	buildForm(): void {
		this.form = new UntypedFormGroup({});
		this.form = this.formBuilder.group({
			visibility: ['OFFLINE', [Validators.required]],
			visibilityDescription: ['', [Validators.maxLength(1000)]],
			channels: [],
			station: this.formBuilder.group({
				id: [null],
				name: ['', [Validators.required]],
			}),
			mobiNumber: [''],
			mobiType: ['', [Validators.required]],
			notes: [''],
			notesInternal: [''],
			numberPlaces: [''],
			geoLocation: this.formBuilder.group({
				longitude: [null],
				latitude: [null]
			}),
			version: [null]
		})
	}
}
