<div class="flex flex-col pb-5">
    
    <!-- Sticky Header -->
    <div class="flex flex-row justify-between items-start w-100 p-5 top-[64px] bg-gui-blue-500 sticky z-10">

        <div class="flex items-center justify-start">
            <div *ngIf="!isAddMode">
                <div *ngIf="space?.publishingStatus === 'PUBLISHED'" class="publishing-status published">Publiziert</div>
                <div *ngIf="space?.publishingStatus === 'ARCHIVED'" class="publishing-status archived">Archiviert</div> 
                <div *ngIf="space?.publishingStatus === 'DRAFT'" class="publishing-status draft">Entwurf</div>  
            </div>
            <div>
                <h1 class="mat-display-1" *ngIf="isAddMode">Neuen Parkraum erstellen</h1>
                <h1 class="mat-display-1" *ngIf="!isAddMode">Parkraum bearbeiten: <b>{{space?.title}}</b> </h1>
                <span class="mat-caption" *ngIf="!isAddMode">
                    <b>ID:</b> {{spaceId}} |
                    <b>Erzeugt:</b> {{space?.created | date: 'dd.MM.yyyy - HH:mm:ss'}} Uhr von {{space?.creator}} |
                    <b>Zuletzt geändert:</b> {{space?.edited | date: 'dd.MM.yyyy - HH:mm:ss'}} Uhr von {{space?.editor}}
                </span>
            </div>
        </div>

        <div>
            <button type="submit" mat-raised-button color="primary" form="ngForm">
                SPEICHERN
            </button>
            <button type="button" [routerLink]="['/space']" mat-raised-button class="!ml-[16px]">
                ZURÜCK
            </button>
        </div>

    </div>

    <div *ngIf="!isAddMode" class="publishing-card sticky top-[164px]">
        <mat-card class="!flex justify-between items-center mat-elevation-z0">
            <div>
                <div *ngIf="space?.publishingStatus === 'PUBLISHED'" class="published">
                    Dieser Parkraum wurde am {{space?.published | date: 'dd.MM.YYYY \'um\' HH:mm:ss \'Uhr\''}} publiziert.
                </div>
                <div *ngIf="space?.publishingStatus === 'ARCHIVED'" class="archived">
                    Dieser Parkraum ist archiviert. Publiziert am {{space?.published | date: 'dd.MM.YYYY \'um\' HH:mm:ss \'Uhr\''}}.
                </div> 
                <div *ngIf="space?.publishingStatus === 'DRAFT'" class="draft">
                    <button class="!mr-3" mat-raised-button color="primary" (click)="publishSpace()">Publizieren</button>Dieser Parkraum ist ein Entwurf.
                </div>  
            </div>  
            <div class="flex items-center">
                <div class="pr-5">Version {{space?.version}}</div>
                <button mat-raised-button (click)="navigateToVersion(space?.version - 1)" [disabled]="!hasPrev" aria-label="Previous version" [style.margin-right.px]="16">
                    <mat-icon>arrow_left</mat-icon> Frühere Version
                </button>
                <button mat-raised-button (click)="navigateToVersion(space?.version + 1)" [disabled]="!hasNext" aria-label="Next version">
                    Spätere Version <mat-icon>arrow_right</mat-icon>
                </button>
            </div> 
        </mat-card>
    </div>

    <form [formGroup]="form" id="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">

        <div class="masonry-mat-cards xl:masonry-mat-cards-grid-3 lg:masonry-mat-cards-grid-2 md:masonry-mat-cards-grid-1 px-5 pt-1">

            <div>

                <mat-card>

                    <mat-card-title>Sichtbarkeit</mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <mat-form-field>
                            <mat-label>Status</mat-label>
                            <mat-select matInput formControlName="visibility">
                                <mat-option [value]="'ONLINE'">ONLINE</mat-option>
                                <mat-option [value]="'OFFLINE'">OFFLINE</mat-option>
                                <mat-option [value]="'PENDING'">PRÜFEN</mat-option>
                                <mat-option [value]="'HIDDEN'">VERSTECKT</mat-option>
                                <mat-option [value]="'DELETED'">GELÖSCHT</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Sichtbarkeit (Grund)</mat-label>
                            <textarea matInput formControlName="visibilityDescription"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                            <mat-error *ngIf="form.get('visibilityDescription').hasError('maxlength')">
                                Die maximale Länge beträgt 1000 Zeichen.
                            </mat-error>
                        </mat-form-field>

                    </mat-card-content>
                
                </mat-card>

                <mat-card>

                    <mat-card-title>Ausspielkanäle</mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <mat-selection-list #channelList formControlName="channels">
                            <mat-list-option *ngFor="let channel of channels; let last = last" [value]="channel.token">
                                {{channel.token}}<br>
                                <mat-hint style="font-size:75%">{{channel.description}}</mat-hint>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </mat-list-option>
                        </mat-selection-list>  
                        
                    </mat-card-content>
                
                </mat-card>

                <mat-card>

                    <mat-card-title>Parkraum</mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <space-autocomplete-stations></space-autocomplete-stations>

                        <mat-form-field>
                            <mat-label>Verantwortlichkeit</mat-label>
                            <mat-select matInput formControlName="responsibility" required>
                                <mat-option [value]="'DBBAHNPARK'">DB BahnPark</mat-option>
                                <mat-option [value]="'PARTNER'">Partner</mat-option>
                                <mat-option [value]="'FOREIGN'">Fremd</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <space-autocomplete-units [isRequired]="form.value.responsibility === 'DBBAHNPARK'"></space-autocomplete-units>

                        <mat-form-field>
                            <mat-label>Parkraum Lage</mat-label>
                            <input matInput formControlName="label">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Name intern</mat-label>
                            <input matInput formControlName="nameInternal">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Name optional</mat-label>
                            <input matInput formControlName="nameOptional">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Name Display</mat-label>
                            <input matInput formControlName="nameDisplay">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Parkart</mat-label>
                            <input matInput formControlName="spaceType">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Parkart EN</mat-label>
                            <input matInput formControlName="spaceTypeEn">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Darstellung Parkart</mat-label>
                            <input matInput formControlName="spaceTypeName">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Flächennummer</mat-label>
                            <input matInput formControlName="spaceNumber">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Quelle</mat-label>
                            <mat-select matInput formControlName="source" required>
                                <mat-option [value]="'DBBAHNPARK'">DBBAHNPARK</mat-option>
                                <mat-option [value]="'PARKOPEDIA'">PARKOPEDIA</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Quellen-ID</mat-label>
                            <input matInput formControlName="sourceId">
                        </mat-form-field>

                        <mat-form-field class="mb-5">
                            <mat-label>Werbetitel</mat-label>
                            <input matInput formControlName="slogan">
                            <mat-hint>Werbetitel, zweites Namensfeld</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="mb-5">
                            <mat-label>Werbetitel EN</mat-label>
                            <input matInput formControlName="sloganEn">
                            <mat-hint>Werbetitel, zweites Namensfeld (en)</mat-hint>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>URL</mat-label>
                            <input matInput formControlName="url">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Betreiber</mat-label>
                            <input matInput formControlName="operator">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Webpräsenz des Betreibers</mat-label>
                            <input matInput formControlName="operatorUrl">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Entfernung</mat-label>
                            <input matInput formControlName="distance">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Art der Abfertigungstechnik</mat-label>
                            <input matInput formControlName="facilityType">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Art der Abfertigungstechnik EN</mat-label>
                            <input matInput formControlName="facilityTypeEn">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Öffnungszeiten</mat-label>
                            <input matInput formControlName="openingHours">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Öffnungszeiten EN</mat-label>
                            <input matInput formControlName="openingHoursEn">
                        </mat-form-field>
                    
                        <mat-checkbox [style.margin-bottom.em]="1.7" formControlName="hasOpeningHours24h">
                            24 Stunden / 7 Tage geöffnet
                        </mat-checkbox>
                        
                        <mat-form-field>
                            <mat-label>Anzahl Stellplätze</mat-label>
                            <input matInput formControlName="numberParkingPlaces">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Stellplätze Mobilitätseingeschränkte</mat-label>
                            <input matInput formControlName="numberHandicapedPlaces">
                        </mat-form-field>

                        <mat-checkbox [style.margin-bottom.em]="1.7" formControlName="hasHandicapedPlaces">
                            Stellplätze für Mobilitätseingeschränkte
                        </mat-checkbox>

                        <mat-form-field>
                            <mat-label>Fahrradstellplatz mieten</mat-label>
                            <textarea matInput formControlName="bikePlacesRental"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                        </mat-form-field>    

                        <mat-form-field>
                            <mat-label>Webpräsenz Fahrradstellplatz mieten</mat-label>
                            <input matInput formControlName="bikePlacesRentalUrl">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fahrradstellplätze (verschließbar)</mat-label>
                            <input matInput formControlName="numberBikePlacesLocked">
                        </mat-form-field>

                        <mat-checkbox [style.margin-bottom.em]="1.7" formControlName="hasBikePlacesLocked">
                            Verschließbare Fahrradstellplätze
                        </mat-checkbox>

                        <mat-form-field>
                            <mat-label>Fahrradstellplätze (offen)</mat-label>
                            <input matInput formControlName="numberBikePlacesOpen">
                        </mat-form-field>

                        <mat-checkbox [style.margin-bottom.em]="1.7" formControlName="hasBikePlacesOpen">
                            Offene Fahrradstellplätze
                        </mat-checkbox>

                        <mat-form-field>
                            <mat-label>Fahrradstellplatz Zusatzinformation</mat-label>
                            <input matInput formControlName="bikePlacesInfo">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Interimstext</mat-label>
                            <input matInput formControlName="outOfServiceText">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Interimstext EN</mat-label>
                            <input matInput formControlName="outOfServiceTextEn">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Reservierung</mat-label>
                            <input matInput formControlName="reservation">
                        </mat-form-field>

                        <mat-checkbox formControlName="hasReservation">
                            Vorherige Reservierung möglich
                        </mat-checkbox>

                        <mat-checkbox formControlName="isOutOfService">
                            Außer Betrieb
                        </mat-checkbox>

                        <mat-checkbox formControlName="isSpecialProductDb">
                            Präsenz eines Sonderproduktes
                        </mat-checkbox>

                        <mat-checkbox formControlName="hasPrognosis">
                            Prognose
                        </mat-checkbox>

                        <mat-checkbox formControlName="hasChargingStation">
                            E-Ladestation
                        </mat-checkbox>

                    </mat-card-content>

                </mat-card>
            
            </div>

            <div>

                <mat-card *ngIf="space?.source === 'PARKOPEDIA'">

                    <mat-card-title>Parkopedia Eigenschaften</mat-card-title>

                    <mat-card-content formGroupName="spaceFlags" class="!flex flex-col">

                        <mat-checkbox formControlName="hasCctv">
                            CCTV
                        </mat-checkbox>  
                        
                        <mat-checkbox formControlName="isGated">
                            Umzäunt
                        </mat-checkbox>  

                        <mat-checkbox formControlName="isLighted">
                            Beleuchtet
                        </mat-checkbox>  
                        
                        <mat-checkbox formControlName="isAttended">
                            Beaufsichtigt
                        </mat-checkbox>  
        
                        <mat-checkbox formControlName="hasDisabledPlaces">
                            Behindertenplätze
                        </mat-checkbox>  

                        <mat-checkbox formControlName="hasParentChildPlaces">
                            Eltern-Kind-Parkplätze
                        </mat-checkbox> 

                        <mat-checkbox formControlName="hasWomenPlaces">
                            Frauenparkplätze
                        </mat-checkbox> 

                        <mat-checkbox formControlName="isMotorCycleFriendly">
                            Motorrad geeignet
                        </mat-checkbox> 

                        <mat-checkbox formControlName="hasLift">
                            Aufzug vorhanden
                        </mat-checkbox> 

                        <mat-checkbox formControlName="hasToilets">
                            Toiletten vorhanden
                        </mat-checkbox> 

                        <mat-checkbox formControlName="hasSecurityFeatures">
                            Extra Sicherheitsmaßnahmen
                        </mat-checkbox> 
                    
                    </mat-card-content>
                
                </mat-card>

                <mat-card>

                    <mat-card-title>Tarif: Steuerung Sonder- und <br>Monatsparkprodukte</mat-card-title>

                    <mat-card-content formGroupName="tariffFlags" class="!flex flex-col">

                        <mat-checkbox formControlName="isDiscountDbParkAndRail">
                            Tageskarte Park & Rail
                        </mat-checkbox>

                        <mat-checkbox formControlName="isDiscountDbBahnComfort">
                            Tageskarte Bahn Comfort
                        </mat-checkbox>

                        <mat-checkbox *ngIf="space?.publishingStatus === 'ARCHIVED'" formControlName="isDiscountDbBahnCard">
                            Tageskarte Bahncard Rabattierung
                        </mat-checkbox>

                        <mat-checkbox *ngIf="space?.publishingStatus !== 'ARCHIVED'" formControlName="isDiscountPCard">
                            Tageskarte Pcard-Rabattierung
                        </mat-checkbox>

                        <mat-checkbox formControlName="isMonthVendingMachine">
                            Monatskarten am Parkscheinautomaten
                        </mat-checkbox>

                        <mat-checkbox formControlName="isMonthParkAndRide">
                            P+R-Monatskarten im Reisezentrum
                        </mat-checkbox>

                        <mat-checkbox formControlName="isMonthSeason">
                            Dauerparkvertrag (3 Monate)
                        </mat-checkbox>

                        <mat-checkbox formControlName="isTariffPaymentCustomerCards">
                            Zahlung Kundenkarten
                        </mat-checkbox>

                        <mat-checkbox formControlName="isTariffPaymentMobile">
                            Mobile Zahlung
                        </mat-checkbox>                 
                    
                    </mat-card-content>
                
                </mat-card>

                <mat-card>

                    <mat-card-title>Tarif: Tarife</mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <app-space-prices-form [source]="space?.source" [form]="form"></app-space-prices-form>

                    </mat-card-content>
                
                </mat-card>

                <mat-card>

                    <mat-card-title>Informationen zum Parkraum</mat-card-title>

                    <mat-card-content formGroupName="spaceInfo" class="!flex flex-col">
                        
                        <mat-form-field class="mb-5">
                            <mat-label>Durchfahrtshöhe</mat-label>
                            <input matInput formControlName="clearanceHeight">
                            <mat-hint>Durchfahrtshöhe in cm</mat-hint>
                        </mat-form-field>
                        
                        <mat-form-field class="mb-5">
                            <mat-label>Durchfahrtsbreite</mat-label>
                            <input matInput formControlName="clearanceWidth">
                            <mat-hint>Durchfahrtsbreite in cm</mat-hint>
                        </mat-form-field>
                        
                        <mat-form-field>
                            <mat-label>Lage Nachtzugang</mat-label>
                            <input matInput formControlName="locationNightAccess">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Zulässige Antriebsarten</mat-label>
                            <input matInput formControlName="allowedPropulsions">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Ladestation</mat-label>
                            <input matInput formControlName="chargingStation">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Telefon</mat-label>
                            <input matInput formControlName="phone">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Beschränkungen</mat-label>
                            <input matInput formControlName="restrictions">
                        </mat-form-field>                 

                    </mat-card-content>
                
                </mat-card>

                <mat-card>

                    <mat-card-title>Koordinaten</mat-card-title>

                    <mat-card-content formArrayName="geoLocation" class="!flex flex-col">

                        <mat-form-field>
                            <mat-label>Längengrad</mat-label>
                            <input matInput formControlName="longitude">
                        </mat-form-field>   

                        <mat-form-field>
                            <mat-label>Breitengrad</mat-label>
                            <input matInput formControlName="latitude">
                        </mat-form-field>   

                    </mat-card-content>
                
                </mat-card>

                <mat-card>

                    <mat-card-title>Adresse</mat-card-title>

                    <mat-card-content formArrayName="address" class="!flex flex-col">

                        <mat-form-field>
                            <mat-label>Straße & Hausnummer</mat-label>
                            <input matInput formControlName="street">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Zufahrt</mat-label>
                            <input matInput formControlName="supplement">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Zufahrt EN</mat-label>
                            <input matInput formControlName="supplementEn">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Postleitzahl</mat-label>
                            <input matInput formControlName="postalCode">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Stadt</mat-label>
                            <input matInput formControlName="city">
                        </mat-form-field>

                    </mat-card-content>
                
                </mat-card>

                <mat-card *ngIf="space?.source !== 'PARKOPEDIA'">

                    <mat-card-title class="!flex justify-between">
                        Dauerparker
                    </mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <mat-form-field>
                            <mat-label>Anzahl Dauerparker</mat-label>
                            <input type="number" matInput formControlName="occupancySeasonParker">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>davon Anzahl reservierte Dauerparker</mat-label>
                            <input type="number" matInput formControlName="seasonParkerReservations">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Anzahl Dauerparker VVS Halbjahreskarten</mat-label>
                            <input type="number" matInput formControlName="seasonParkerVvs">
                        </mat-form-field>

                    </mat-card-content>

                </mat-card>

                <mat-card *ngIf="space?.source !== 'PARKOPEDIA'">
                    <mat-card-title class="!flex justify-between">
                        <span>Parkspotting Stammdaten</span>
                    </mat-card-title>

                    <mat-card-content class="!flex flex-col">

                        <mat-slide-toggle formControlName="isParkspotting" (change)="onParkspottingChange($event)" class="mb-6">
                            {{form.get('isParkspotting').value ? 'Aktiv' : 'Inaktiv'}}
                        </mat-slide-toggle>

                        <mat-form-field>
                            <mat-label>Parkspotting-Kontingent</mat-label>
                            <input type="number" matInput formControlName="capacityParkspotting">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Parkschein App Id</mat-label>
                            <input type="number" matInput formControlName="psAppId">
                        </mat-form-field>

                        <mat-form-field class="mb-2">
                            <mat-label>Parkscheinautomaten Ids</mat-label>
                            <mat-chip-list #psaIdsList multiple formControlName="psaIds">
                            <mat-chip *ngFor="let psaId of form.get('psaIds').value"
                                    [value]="psaId"
                                    (removed)="removeChipValue(psaId, 'psaIds')">
                                {{psaId}}
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input
                                placeholder="Id hinzufügen..."
                                [matChipInputFor]="psaIdsList"
                                (matChipInputTokenEnd)="addChipValue($event, 'psaIds')">
                            </mat-chip-list>
                            <mat-hint>Eine oder mehrere Ids eintragen und mit ENTER bestätigen.</mat-hint>
                        </mat-form-field>

                    </mat-card-content>

                </mat-card>

            </div>

            <div>

                <mat-card>

                    <mat-card-title>Tarif: Info</mat-card-title>

                    <mat-card-content formGroupName="tariffInfo" class="!flex flex-col">

                        <mat-form-field>
                            <mat-label>Sondertarif</mat-label>
                            <textarea matInput formControlName="tariffSpecial"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Sondertarif EN</mat-label>
                            <textarea matInput formControlName="tariffSpecialEn"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Frei Parken (Kiss&Ride)</mat-label>
                            <input matInput formControlName="tariffFreeParkingTime">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Frei Parken (Kiss&Ride) EN</mat-label>
                            <input matInput formControlName="tariffFreeParkingTimeEn">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Beschränkte Parkdauer</mat-label>
                            <input matInput formControlName="tariffMaxParkingTime">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Beschränkte Parkdauer EN</mat-label>
                            <input matInput formControlName="tariffMaxParkingTimeEn">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Tarifkennung</mat-label>
                            <input matInput formControlName="tariffIdentifier">
                        </mat-form-field>

                        <mat-form-field class="mb-5">
                            <mat-label>Sonderprodukte Vorverkauf</mat-label>
                            <textarea matInput formControlName="tariffPointOfSale"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                            <mat-hint>Besondere Vertriebswegangaben Sonderprodukte: Vorverkauf</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="mb-5">
                            <mat-label>Sonderprodukte Vorverkauf EN</mat-label>
                            <textarea matInput formControlName="tariffPointOfSaleEn"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                            <mat-hint>Besondere Vertriebswegangaben Sonderprodukte: Vorverkauf (en)</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="mb-5">
                            <mat-label>Sonderprodukte Rabattierung</mat-label>
                            <textarea matInput formControlName="tariffDiscount"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                            <mat-hint>Besondere Vertriebswegangaben Sonderprodukte: Rabattierung</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="mb-5">
                            <mat-label>Sonderprodukte Rabattierung EN</mat-label>
                            <textarea matInput formControlName="tariffDiscountEn"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                            <mat-hint>Besondere Vertriebswegangaben Sonderprodukte: Rabattierung (en)</mat-hint>
                        </mat-form-field>

                        <div *ngIf="form.get('tariffInfo.tariffNotesFormatted').value" 
                             class="flex items-start justify-start border border-solid border-[#ffe900] rounded-md p-4 bg-[#fff9e2] text-[#1c0000] mb-5">
                            <mat-icon class="flex-shrink-0 mr-2">tips_and_updates</mat-icon>
                            <p class="!m-0"><b>Es befindet sich formatierter Text im Editor!</b><br>
                            Das Feld <b><code>Hinweis an Kunden</code></b> wird daher in der Ausgabe (z.B.: Web, PDF) mit dem Inhalt des Text Editors überschrieben. Löschen oder ändern Sie diesen Text, um Änderungen in der Ausgabe zu sehen.</p>
                        </div>

                        <mat-form-field>
                            <mat-label>Hinweis an Kunden</mat-label>
                            <textarea matInput formControlName="tariffNotes"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                            <mat-hint>Hinweis an Kunden zum Parkraum (Besonderheiten)</mat-hint>
                        </mat-form-field>

                        <mat-accordion class="mb-5">
                            <mat-expansion-panel class="mat-elevation-z0" expanded="true">
                                <mat-expansion-panel-header>
                                <mat-panel-title>Text Editor</mat-panel-title>
                                <mat-panel-description>Überschreibt das Feld: Hinweis an Kunden</mat-panel-description>
                                </mat-expansion-panel-header>
                                    <editor class="mb-5"
                                        formControlName="tariffNotesFormatted"
                                        [inline]="false"
                                        [init]="tinymceConfig">
                                    </editor>  
                            </mat-expansion-panel>
                        </mat-accordion>  

                        <div *ngIf="form.get('tariffInfo.tariffNotesFormattedEn').value"
                            class="flex items-start justify-start border border-solid border-[#ffe900] rounded-md p-4 bg-[#fff9e2] text-[#1c0000] mb-5">
                            <mat-icon class="flex-shrink-0 mr-2">tips_and_updates</mat-icon>
                            <p class="!m-0"><b>Es befindet sich formatierter Text im Editor!</b><br>
                            Das Feld <b><code>Hinweis an Kunden EN</code></b> wird daher in der Ausgabe (z.B.: Web, PDF) mit dem Inhalt des Text Editors überschrieben. Löschen oder ändern Sie diesen Text, um Änderungen in der Ausgabe zu sehen.</p>
                        </div>

                        <mat-form-field>
                            <mat-label>Hinweis an Kunden EN</mat-label>
                            <textarea matInput formControlName="tariffNotesEn"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                            <mat-hint>Hinweis an Kunden zum Parkraum (Besonderheiten) (en)</mat-hint>
                        </mat-form-field>        

                        <mat-accordion class="mb-5">
                            <mat-expansion-panel class="mat-elevation-z0" expanded="true">
                                <mat-expansion-panel-header>
                                <mat-panel-title>Text Editor</mat-panel-title>
                                <mat-panel-description>Überschreibt das Feld: Hinweis an den Kunden EN</mat-panel-description>
                                </mat-expansion-panel-header>
                                    <editor
                                        formControlName="tariffNotesFormattedEn"
                                        [inline]="false"
                                        [init]="tinymceConfig">
                                    </editor>     
                            </mat-expansion-panel>
                        </mat-accordion>

                        <mat-form-field>
                            <mat-label>Allgemeine Bemerkungen (nur intern)</mat-label>
                            <textarea matInput formControlName="tariffNotesInternal"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field class="mb-5">
                            <mat-label>Hinweise zur Tarifbox</mat-label>
                            <textarea matInput formControlName="tariffRemarks"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                            <mat-hint>Tarifbezogenes Bemerkungsfeld: Hinweise an Kunden zur Tarifbox</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="mb-5">
                            <mat-label>Hinweise zur Tarifbox EN</mat-label>
                            <textarea matInput formControlName="tariffRemarksEn"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                            <mat-hint>Tarifbezogenes Bemerkungsfeld: Hinweise an Kunden zur Tarifbox (en)</mat-hint>
                        </mat-form-field>

                        <mat-form-field *ngIf="space?.source === 'PARKOPEDIA'">
                            <mat-label>Zahlungsmöglichkeiten Kurzform</mat-label>
                            <input matInput formControlName="tariffPaymentType">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Zahlungsmedien</mat-label>
                            <textarea matInput cdkTextareaAutosize
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="10" formControlName="tariffPaymentOptions"></textarea>
                        </mat-form-field>
                        
                        <mat-form-field>
                            <mat-label>Zahlungsmedien (en)</mat-label>
                            <textarea matInput cdkTextareaAutosize
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="10" formControlName="tariffPaymentOptionsEn"></textarea>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Mobile Zahlungsarten</mat-label>
                            <textarea matInput formControlName="tariffPaymentMobile"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Kundenkarten</mat-label>
                            <input matInput formControlName="tariffPaymentCustomerCards">
                        </mat-form-field>

                    </mat-card-content>
                
                </mat-card>

            </div>

        </div>

    </form>

</div>


<div style="
    display: none; 
    position: fixed; 
    right: 0; 
    top:218px; 
    bottom:0; 
    width:25vw; 
    height: calc(70% - 154px);
    background:white; 
    overflow: scroll;
    padding:1em;
    box-shadow:1px 1px 1px #444">
    <pre><code>{{ form.value | json }}</code></pre>
</div>

<div style="
    display: none; 
    position: fixed; 
    right: 25vw; 
    top:154px; 
    bottom:0; 
    width:25vw; 
    height: calc(100% - 154px);
    background:white; 
    overflow: scroll;
    padding:1em;
    box-shadow:1px 1px 1px #444">
    <pre><code>{{ patchedFormValue | json }}</code></pre>
</div>
